import { createTheme } from "@material-ui/core/styles";
import AvenirBlack from "../static/fonts/avenir-black.woff2";
import AvenirBook from "../static/fonts/avenir-book.woff2";
import AvenirMedium from "../static/fonts/avenir-medium.woff2";
import MohrBlack from "../static/fonts/mohr-rounded-black.woff2";
import MohrBlackIt from "../static/fonts/mohr-rounded-blackIt.woff2";
import MohrBold from "../static/fonts/mohr-rounded-bold.woff2";


//this comes from the app source codes.
const appColors = {
    "blue": "#63B0D4",
    "blueLight": "#85C1DD",
    "blueLighter": "#D8F2FF",
    "blueDark": "#4894B9",
    "blueMediumDark": "#82B4CC",
    "grey": "#F0EFF5",
    "greySuperLight": "#ECECEC",
    "greyLight": "#888888",
    "greyMediumDark": "#D1D1D1",
    "greyDark": "#4C4C4C",
    "red": "#DD7C74",
    "green": "#59bf7a",
    "greenDark": "#5CAF77",
    "brown": "#664942",
    "brownLight": "#7f6964",
    "yellow": "#DCC337",
    "attension": "#F2DEDE",
    "white": "#FFFFFF",
    "offWhite": "#FEFDF9",
    "primary": "#63B0D4",
    "primaryDark": "#4E8BA8",
    "primaryDarker": "#41738B",
    "primaryLight": "#7BC8EC",
    "primaryLighter": "#D8F2FF",
    "fontPrimary": "#664942",
    "fontPrimaryLight": "#7f6964",
    "fontSecondary": "#5b7179",
    "link": "#00A2E0"
};


const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1280,
            xl: 1920
        },
    },
});

const xxs = 450;


export const adminTheme = createTheme({
    palette: {
        primary: {
            main: '#0d47a1',
        },
        secondary: {
            main: '#03a9f4',
        },
        background: {
            default: '#fafafa'
        }
    }
});

export const webTheme = createTheme({
    palette: {
        primary: {
            main: appColors.green,
            contrastText: "#fff"
        },
        secondary: {
            main: "#472C1C",
        },
        text: {
            primary: '#472C1C',
            secondary: appColors.greyDark,
        },
        background: {
            default: '#fafafa'
        },
        contrastThreshold: 2
    },
    typography: {
        fontFamily: "Avenir",
        h1: {
            fontWeight: 600,
            fontSize: "4.5rem",
            lineHeight: 1.167,
            letterSpacing: "-0.01562em",
            [theme.breakpoints.down('sm')]: {
                fontSize: '4rem'
            },
            [theme.breakpoints.down('xs')]: {
                fontSize: '3.5rem'
            },
            [theme.breakpoints.down(xxs)]: {
                fontSize: '3rem'
            }
        },
        h2: {
            fontWeight: 600,
            fontSize: "4.2rem",
            lineHeight: 1.167,
            letterSpacing: "-0.01562em",
            [theme.breakpoints.down('sm')]: {
                fontSize: '3.7rem'
            },
            [theme.breakpoints.down('xs')]: {
                fontSize: '3.2rem'
            },
            [theme.breakpoints.down(xxs)]: {
                fontSize: '2.7rem'
            }
        },
        h3: {
            fontWeight: 600,
            fontSize: "3.9rem",
            lineHeight: 1.167,
            letterSpacing: "-0.01562em",
            [theme.breakpoints.down('sm')]: {
                fontSize: '3.4rem'
            },
            [theme.breakpoints.down('xs')]: {
                fontSize: '2.9rem'
            },
            [theme.breakpoints.down(xxs)]: {
                fontSize: '2.4rem'
            }
        },
        h4: {
            fontWeight: 600,
            fontSize: "3.6rem",
            lineHeight: 1.167,
            letterSpacing: "-0.01562em",
            [theme.breakpoints.down('sm')]: {
                fontSize: '3.1rem'
            },
            [theme.breakpoints.down('xs')]: {
                fontSize: '2.6rem'
            },
            [theme.breakpoints.down(xxs)]: {
                fontSize: '2.1rem'
            }
        },
        h5: {
            fontWeight: 600,
            fontSize: "2rem",
            lineHeight: 1.167,
            letterSpacing: "-0.01562em",
            [theme.breakpoints.down('sm')]: {
                fontSize: '1.4rem'
            },
            [theme.breakpoints.down('xs')]: {
                fontSize: '1rem'
            }
        },
        h6: {
            fontWeight: 600,
            fontSize: "1.5rem",
            lineHeight: 1.167,
            letterSpacing: "-0.01562em",
            [theme.breakpoints.down('sm')]: {
                fontSize: '1rem'
            }
        },
        subtitle1: {
            fontWeight: 400,
            fontSize: "2rem",
            lineHeight: 1.167,
            letterSpacing: "0.00938em",
            [theme.breakpoints.down('sm')]: {
                fontSize: '1.8rem'
            },
            [theme.breakpoints.down('xs')]: {
                fontSize: '1.5rem'
            },
            [theme.breakpoints.down(xxs)]: {
                fontSize: '1.2rem'
            }
        },
        subtitle2: {
            fontWeight: 500,
            fontSize: "1.8rem",
            lineHeight: 1.57,
            letterSpacing: "0.00714em",
            [theme.breakpoints.down('sm')]: {
                fontSize: '1.6rem'
            },
            [theme.breakpoints.down('xs')]: {
                fontSize: '1.3rem'
            },
            [theme.breakpoints.down(xxs)]: {
                fontSize: '1.2rem'
            }
        },
        body1: {
            fontWeight: 400,
            fontSize: "1rem",
            lineHeight: 1.5,
            letterSpacing: "0.00938em"
        },
        body2: {
            fontWeight: 600,
            fontSize: "1.3rem",
            lineHeight: 1.43,
            letterSpacing: "0.00938em",
            [theme.breakpoints.down('sm')]: {
                fontSize: "1.1rem",
            },
            [theme.breakpoints.down('xs')]: {
                fontSize: "1rem",
            }
        }
    },
    overrides: {
        MuiCssBaseline: {
            '@global': {
                '@font-face': [{
                    fontFamily: 'Avenir',
                    fontStyle: 'normal',
                    fontDisplay: 'block',
                    fontWeight: 200,
                    src: `url(${AvenirBook}) format('woff2')`
                },
                {
                    fontFamily: 'Avenir',
                    fontStyle: 'normal',
                    fontDisplay: 'block',
                    fontWeight: 400,
                    src: `url(${AvenirMedium}) format('woff2')`
                },
                {
                    fontFamily: 'Avenir',
                    fontStyle: 'normal',
                    fontDisplay: 'block',
                    fontWeight: 900,
                    src: `url(${AvenirBlack}) format('woff2')`
                },
                {
                    fontFamily: 'Mohr',
                    fontStyle: 'normal',
                    fontDisplay: 'block',
                    fontWeight: 900,
                    src: `url(${MohrBlack}) format('woff2')`
                },
                {
                    fontFamily: 'Mohr',
                    fontStyle: 'italic',
                    fontDisplay: 'block',
                    fontWeight: 'normal',
                    src: `url(${MohrBlackIt}) format('woff2')`
                },
                {
                    fontFamily: 'Mohr',
                    fontStyle: 'normal',
                    fontDisplay: 'block',
                    fontWeight: 'normal',
                    src: `url(${MohrBold}) format('woff2')`
                }],
                'html': {
                    fontFamily: "Avenir",
                },
                'h1, h2, h3, h4, h5, h6': {
                    fontFamily: 'Mohr',
                },
                'a': {
                    color: "#62aed6"
                }
            },
        },
        MuiContainer: {
            root: {
                paddingLeft: "20px",
                paddingRight: "20px",

                [theme.breakpoints.up('sm')]: {
                    paddingLeft: "70px",
                    paddingRight: "70px"
                }
            }
        },
        MuiTypography: {
            root: {
                '&.bodyText': {
                    fontFamily: "Avenir"
                }
            },
            h1: {
                fontFamily: "Mohr",
                paddingBottom: "10px"
            },
            h2: {
                fontFamily: "Mohr",
                paddingBottom: "10px"
            },
            h3: {
                fontFamily: "Mohr",
                paddingBottom: "10px"
            },
            h4: {
                fontFamily: "Mohr",
                paddingBottom: "10px"
            },
            h5: {
                fontFamily: "Mohr",
                paddingBottom: "10px"
            },
            h6: {
                fontFamily: "Mohr",
                paddingBottom: "10px"
            },
            subtitle1: {
                fontFamily: "Mohr",
            },
            subtitle2: {
                fontFamily: "Mohr",
            }
        },
        MuiLink: {
            root: {
                color: "#62aed6"
            }
        },
        MuiButton: {
            root: {
                textTransform: "none",
                fontWeight: 600,
                borderRadius: "14px",
                padding: "6px 18px",
                fontSize: "1.3rem"
            },
            contained: {
                boxShadow: "none",
                '&:hover, &:active': {
                    boxShadow: "none"
                }
            },
            sizeLarge: {
                fontSize: "1.3rem",
                padding: "10px 26px",
                [theme.breakpoints.down('sm')]: {
                    padding: "10px 26px",
                    fontSize: "1.1rem"
                }
            }
        },
        MuiChip: {
            root: {
                fontFamily: "Mohr !important"
            }
        }
    },
});

