import ToastContainer from "@components/ToastContainer";
import { CssBaseline } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles";
import { LocationProvider } from "@reach/router";
import "@utils/i18n";
import i18n from "@utils/i18n";
import { Helmet } from "react-helmet";
import { I18nextProvider } from "react-i18next";
import AvenirBlack from "../../../static/fonts/avenir-black.woff2";
import AvenirBook from "../../../static/fonts/avenir-book.woff2";
import AvenirMedium from "../../../static/fonts/avenir-medium.woff2";
import MohrBlack from "../../../static/fonts/mohr-rounded-black.woff2";
import MohrBlackIt from "../../../static/fonts/mohr-rounded-blackIt.woff2";
import MohrBold from "../../../static/fonts/mohr-rounded-bold.woff2";
import { webTheme } from "../../theme";

export function withWebLayout(Component: (props: any) => JSX.Element | null) {
  return (props: any) => <>
    <Helmet htmlAttributes={{lang: 'da'}}>
      <meta name="viewport" content="width=device-width, initial-scale=1" />

      <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png?v=1" />
      <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png?v=1" />
      <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png?v=1" />
      <link rel="manifest" href="/site.webmanifest?v=1" />

      <link rel="preload" href={AvenirBook} as="font" type="font/woff2" crossOrigin="anonymous" />
      <link rel="preload" href={AvenirMedium} as="font" type="font/woff2" crossOrigin="anonymous" />
      <link rel="preload" href={AvenirBlack} as="font" type="font/woff2" crossOrigin="anonymous" />
      <link rel="preload" href={MohrBlack} as="font" type="font/woff2" crossOrigin="anonymous" />
      <link rel="preload" href={MohrBlackIt} as="font" type="font/woff2" crossOrigin="anonymous" />
      <link rel="preload" href={MohrBold} as="font" type="font/woff2" crossOrigin="anonymous" />

      <link rel="dns-prefetch" href="https://app.reshopper.com" />
      <link rel="preconnect" href="https://cached-app.reshopper.com" />
      <link rel="dns-prefetch" href="https://cached-app.reshopper.com" />

      <style type="text/css">
        {`

html {
  font-size: 16px !important;
}

body, #___gatsby, #gatsby-focus-wrapper {
  min-height: 100vh;
}

textarea:focus, input:focus{
  outline: none;
}
    `}
      </style>
    </Helmet>
    <LocationProvider>
        <ThemeProvider theme={webTheme}>
          <I18nextProvider i18n={i18n}>
            <CssBaseline />
            <Component {...props} />
            <ToastContainer />
          </I18nextProvider>
        </ThemeProvider>
    </LocationProvider>
  </>;
}