import languageDa from './da.json';
import languageEn from './en.json';

let language: "da"|"en"|undefined;

export function getLanguage() {
	return language || "en";
}

export function setLanguage(newLanguage: typeof language) {
	language = newLanguage;
}

export default function L(
	key: keyof (typeof languageDa | typeof languageEn),
	...args: (string|number|["toUpperCase"|"toLowerCase"])[]
):string {
	const languages = {
		'da': languageDa,
		'en': languageEn
	};
	const language = languages[getLanguage()] as any || languages['en']; 

	const keySplit = key.split(".");
	
	let template = language[keySplit[0]];
	for(let i=1;i<keySplit.length;i++) {
		template = template[keySplit[i]];
	}
	
	if(args && args.length > 0)
		template = format(template, ...args);

	return template;
}


//a function that takes a string and replaces "%X$s" instances with the arguments given where X is the index of the match
//e.g. format("Hello %2$s %1$s", "Doe", "John") => "Hello John Doe"
function format(str: string, ...args: (string|number|["toUpperCase"|"toLowerCase"])[]) {

		str = str.replace(/%(\d+)\$s/g, (_, match) => {
			const replaceString = args[(+match) - 1];
			if(Array.isArray(replaceString))
				throw new Error("You must provide a string to replace your value with");

			return replaceString?.toString();
		});

		let offset = 0;
		while(str.indexOf("%s") > -1) {
			str = str.replace(/%s/, () => {
				const replaceString = args[offset++];
				if(Array.isArray(replaceString))
					throw new Error("Trying to replace with Array. You must provide a string to replace your value with");
				
				return replaceString?.toString();
			});
		}

		const lastElement = args[args.length - 1];
		if(Array.isArray(lastElement) && lastElement.length){
			const modifyer = lastElement[0];
			if(typeof str[modifyer] === "function"){
				str = str[modifyer]();
			}
		}

	return str;
}